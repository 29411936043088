@import "colors";

// default validation styles for form groups
.form-group {
  &.has-error {
    .form-control {
      border-color: $error-red !important;
    }

    .validation-message {
      color: $error-red;
    }
  }

  &.has-warning {
    .form-control {
      border-color: $warning-orange !important;
    }

    .validation-message {
      color: $warning-orange;
    }
  }

  &.has-info {
    .validation-message {
      color: $info-blue;
    }
  }
}