@import "src/app/common/styles/functions";
@import "src/app/common/styles/fonts";

.adm4-tooltip,
.adm4-tooltip .tooltip-inner {
  background-color: #EAEAEA;

  font: $pattern-heading-font;
  color: black;
}

.adm4-tooltip {
  max-width: 600px;
  max-height: 80vh;
  overflow: auto;

  &.small-tooltip {
    max-width: 300px;
  }

  &.show {
    opacity: 1;
  }

  &.fade {
    transition: opacity .1s linear;
  }

  &.behind-dialog {
    z-index: 999;
  }

  z-index: 1001;

  box-shadow: 0 10px 15px -3px rgb(0 0 0/0.1),0 4px 6px -4px rgb(0 0 0/0.1);
  padding: 0;
  border-radius: 4px;
  border: 1px solid #D8D8D8;

  cursor: default;

  .tooltip-inner {
    padding: default_spacing_multiplier(0.5) default_spacing_multiplier(1);
    max-width: 100%;
    text-align: left;

    :last-child {
      margin-bottom: 0;
    }
  }
}
