@import "variables";
@import "functions";
@import "fonts";
@import "colors";
@import "mixins";

// Normalize
button {
  border: none;
  outline: none;
  box-shadow: none;

  &:not([disabled]) {
    cursor: pointer;
  }
}

// Container for multiple buttons
.admn4-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: $default-padding-base default_spacing_multiplier(4);
  border-top: 1px solid $line-grey;
  background: $screen-white;

  & > *:not(:last-child) {
    margin-right: default_spacing_multiplier(3);
  }
}

/* Ellipse buttons */
.admn4-button-ellipse-default {
  @include admn4-button-ellipse();
}

.admn4-button-ellipse-blue {
  @extend .admn4-button-ellipse-default;
  background-color: $nevis-green-c1;
  color: $white-c0;

  &[disabled] {
    background-color: $light-grey;
    color: $inactive-grey;
  }

  &:focus {
    @include admn4-button-focus($nevis-green-c1);
  }
}

.admn4-button-ellipse-yellow {
  @extend .admn4-button-ellipse-default;
  background-color: $focus-color-yellow-off;
  color: $screen-black;

  &[disabled] {
    background-color: $light-grey;
    color: $inactive-grey;
  }

  &:focus {
    @include admn4-button-focus($focus-color-yellow-off);
  }
}

.admn4-button-ellipse-red {
  @extend .admn4-button-ellipse-default;
  background-color: $error-red;
  color: $screen-white;

  &[disabled] {
    background-color: $light-error-red;
    color: $menu-icon-grey;
  }

  &:focus {
    @include admn4-button-focus($error-red);
  }
}
/* End of ellipse buttons*/

/* Filter buttons */
.admn4-button-filter {
  background-color: $focus-color-blue-off;
  color: $white-c0;
  border-radius: 3px;
  -webkit-transition: background-color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;

  &:not([disabled]):hover, &.selected {
    background-color: $focus-color-blue-on;
  }

  &:focus {
    @include admn4-button-focus($focus-color-blue-on);
  }
}

.admn4-button-filter-icon {
  @extend .admn4-button-filter;
  width: 2em;
  height: 2em;
}

.admn4-button-filter-category {
  @extend .admn4-button-filter;

  :hover {
    cursor: pointer;
  }

  &:focus {
    @include admn4-button-focus($focus-color-blue-on);
  }

  &.selected {
    background-color: $focus-color-blue-on;
    color: $white-c0;
    border-radius: 5px;
    box-shadow: 1px 2px 10px $focus-color-blue-on;
  }
}

.admn4-button-filter-pattern-icon {
  @extend .admn4-button-filter-category;
  font: $standard-light-p-font;
  width: 1.5em;
  height: 1.5em;
  padding: 0 1px;
}

/* End of filter buttons */

/* Text buttons */
.admn4-button-text {
  @include admn4-button-text();

  &:not([disabled]):hover {
    text-decoration: underline;
  }

  &:focus {
    @include admn4-button-focus($menu-icon-grey);
  }

  &:has(mat-icon) {
    display: flex;
    flex-direction: row;
    align-items: center;

    mat-icon {
      margin-right: default_spacing_multiplier(1);
    }
  }
}

/* End of text buttons */

/* Icon buttons */
.admn4-button-icon {
  @include admn4-button-icon($inactive-grey, $nevis-green-c1);
}

.admn4-button-icon-white {
  @include admn4-button-icon($white-c0, $white-c0);
}

.admn4-button-nevis-green {
  @include admn4-button-icon($nevis-green-c1, $nevis-green-c1);
}


.admn4-button-sorting {
  @include admn4-button-icon($inactive-grey, $inactive-grey, $nevis-green-c1);
}

/* End of icon buttons */

$bg-var-inactive: url('../../../assets/varInactive.png');
$bg-var-active: url('../../../assets/varActive.png');
$bg-var-selected: url('../../../assets/varSelected.png');
$bg-var-selected-readonly: url('../../../assets/varSelectedReadOnly.png');

.adm4-button-variable {
  min-width: 35px;
  max-width: 35px;
  height: 16px;
  background-size: 100% 100%;
  background-image: $bg-var-inactive;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: $default-input-border-radius;

  &.active, &:hover {
    background-image: $bg-var-active;
  }

  &:focus {
    @include admn4-button-focus($focus-color-blue-on);
  }

  &:disabled {
    background-image: $bg-var-inactive;
  }

  &.selected {
    background-image: $bg-var-selected;
    cursor: default;

    &[disabled] {
      background-image: $bg-var-selected-readonly;
    }
  }
}

button[mat-fab].admn4-button-hero {
  position: absolute;
  left: default_spacing_multiplier(5);
  bottom: default_spacing_multiplier(2);
  height: 44px;
  width: 44px;
  background-color: $nevis-green-c1;
  color: $white-c0;
  z-index: 10;
}

.mat-mdc-icon-button.hamburger {
  width: 2rem;
  height: 2rem;
  line-height: 1;
}

.focus-nevis-green:focus {
  @include admn4-button-focus($nevis-green-c1);

  &.selected,
  &.active {
    outline: none;
  }
}

.focus-bg-light-grey:focus {
  background-color: rgba(0, 0, 0, 0.04) !important;
  outline: none;

  &.selected,
  &.active {
    background-color: unset !important;
  }
}

.focus-bg-light-nevis-blue:focus {
  background-color: rgba(185, 221, 229, 0.3) !important;
  outline: none;

  &.selected,
  &.active {
    background-color: unset !important;
  }
}
