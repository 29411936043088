@import "src/app/common/styles/mixins";
@import "src/app/common/styles/component-specific/marketplace-variables";

.mp-tile {
  display: block;
  width: 300px;
  height: $mp-tile-height;

  cursor: pointer;
  background: $white-c0;
  border: $mp-tile-border-width solid $line-grey;
  border-radius: $mp-tile-border-radius;
  transition: box-shadow 0.1s ease-in-out;

  padding: default_spacing_multiplier(2);

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    border-color: $nevis-green-light-c2;
  }

  &[disabled],
  &[disabled] label,
  &[disabled] mat-icon {
    cursor: not-allowed;
    color: $disabled-grey;
  }

  &.selected {
    border: $mp-tile-border-width solid $nevis-green-c1;
    box-shadow: $selected-element-box-shadow;
  }
}
