@import "colors";
@import "fonts";
@import "functions";
@import "variables";

.inline-help {

  section {
    position: relative;
    color: $menu-icon-grey;
    padding: default_spacing_multiplier(1) 0;

    &:not(:last-child)::after {
      content: ' ';
      position: absolute;
      bottom: 0;
      width: calc(100%);
      height: 3px;
      border-bottom: 1px solid $line-grey;
      border-top: 1px solid $line-grey;
    }

    & > *:not(:last-child):not(:empty) {
      margin-bottom: default_spacing_multiplier(1);
    }

    h2 {
      font: $pattern-heading-font;
      color: $nevis-green-c1;
    }

    h3 {
      font: $standard-p-font;
      color: $nevis-green-c1;
    }

    p {
      font: $standard-light-p-font;
    }

    pre {
      margin: default_spacing_multiplier(1) 0;
      padding: default_spacing_multiplier(1);
      font: $help-code-font;
      border-radius: $default-input-border-radius;
      background-color: $screen-white;
      outline: 1px solid $disabled-grey;
    }

    .pill {
      display: inline-block;
      padding: 2px 4px;
      border-radius: 4px;
      margin: 4px 0;
      font-weight: bold;

      &.yellow {
        background: $inventory-yellow;
      }

      &.red {
        background: $inventory-red;
        color: $screen-white;
      }

      &.green {
        color: $screen-white;
        background: $inventory-green;
      }

      &.purple {
        background: $inventory-purple;
        color: $screen-white;
      }

      &.brown {
        background: $inventory-brown;
        color: $screen-white;
      }

      &.blue {
        background: $inventory-blue;
        color: $screen-white;
      }
    }

    .admonition-warning,
    .admonition-info {
      margin-top: default_spacing_multiplier(1);
      margin-bottom: default_spacing_multiplier(1);
      padding: default_spacing_multiplier(1);
      border-radius: $default-input-border-radius;

      *:last-child {
        margin-bottom: 0;
      }
    }
    .admonition-warning {
      background-color: $light-warning-orange;
      outline: 1px solid $warning-orange
    }
    .admonition-info {
      background-color: $light-info-blue;
      outline: 1px solid $info-blue
    }
  }
}
