@use '@angular/material' as mat;
@import "@angular/material/core/theming/palette";  // contains the default mat colors

@import "bootstrap-custom";

@import "fonts_families";
@import "fonts";
@import "colors";
@import "../../notification/notification-toaster";
@import "buttons";
@import "inputs";
@import "icons";
@import "util-classes";
@import "form-validation";
@import "dialogs";
@import "panel-classes";
@import "inline-help";
@import "global-text-classes";
@import "monaco-editor";
@import "marketplace";
@import "tooltip";
@import "css_components";
@import "mat-table";
@import "splitpane";
@import "inventory-global-styles";

.DEBUG-blue * {
  outline: 1px solid rgba(0 100 255 / 0.5);
}

.DEBUG-red * {
  outline: 1px solid rgba(255 0 0 / 0.5);
}

// the values here were debugged from the default button level typography, they are reused, except the letter spacing, which is overridden to `normal`.
$button-typography-level: mat.define-typography-level(
  $font-weight: 500,
  $font-size: 14px,
  $line-height: 1.375rem,
  $letter-spacing: normal,
);

$main-font-family: 'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif';

$main-typography: mat.define-typography-config(
  $font-family: $main-font-family,
  $button: $button-typography-level,
);

//  As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
@include mat.all-component-typographies;

// TODO NEVISADMV4-9241 improve typography
.mat-mdc-dialog-container div.mdc-dialog__content,
.mat-mdc-radio-button label.mdc-label {
  font-size: 1rem;
  color: #000000de;
  letter-spacing: normal;
  line-height: normal;
}

@include mat.core();

$mat-nevis-primary: (
        50 : #e3f1f5,
        100 : #b9dde5,
        200 : #8bc6d4,
        300 : #5cafc3,
        400 : #399db6,
        500 : $nevis-green-c1,
        600 : #1384a2,
        700 : #107998,
        800 : #0c6f8f,
        900 : #065c7e,
        A100 : #ade4ff,
        A200 : #7ad4ff,
        A400 : #47c3ff,
        A700 : #2dbbff,
        contrast: (
                50: $dark-primary-text,
                100: $dark-primary-text,
                200: $dark-primary-text,
                300: $dark-primary-text,
                400: $dark-primary-text,
                500: $light-primary-text,
                600: $light-primary-text,
                700: $light-primary-text,
                800: $light-primary-text,
                900: $light-primary-text,
                A100: $dark-primary-text,
                A200: $dark-primary-text,
                A400: $dark-primary-text,
                A700: $dark-primary-text,
        )
);

$nevis-primary: mat.define-palette($mat-nevis-primary);
$nevis-accent: mat.define-palette(mat.$blue-palette);

// The warn palette is optional (defaults to red).
$nevis-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$nevis-theme: mat.define-light-theme((
  color: (
    primary: $nevis-primary,
    accent: $nevis-accent,
    warn: $nevis-warn,
  ),
  typography: $main-typography,
));

@include mat.all-component-themes($nevis-theme);

@include mat.checkbox-density(-1);
@include mat.radio-density(-1);
@include mat.slide-toggle-density(-1);

/* Global Style Reset */
/* You can add global styles to this file, and also import other style files */
* {
  margin: 0;
}

html, body {
  font: $default-font;
  font-size: $default-font-size;
  height: 100%;
}

// removing the margin from the label, originally added by bootstrap's reboot
label {
  margin-bottom: 0;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: lightgray;
}

button, input, optgroup, select, textarea {
  font-family: $font_default_body !important;
}

textarea.code {
  font-family: $code-font-family !important;
}

a {
  @include nevis-green-link();

  &:not([href]):not([tabindex]) {
    @include nevis-green-link();
  }

  &.link-with-icon {
    display: inline-flex;
    align-items: flex-end;

    &:hover {
      text-decoration: none !important;

      span {
        text-decoration: underline;
      }
    }

    .material-icons {
      @include mat-icon-size(16px);
      margin-left: default_spacing_multiplier(0.5);
    }
  }
}

a.disabled {
  pointer-events: none;
  cursor: pointer;
  color: $disabled-grey;
}

.material-icons {
  @include material-icon();
}

// mat-icon repeated twice to make it important to override original styles and not to use !important
.mat-icon.mat-icon {
  // fixes layout of svg mat-icons
  display: inline-flex;
}

mat-tab-group mat-tab-header,
.mat-mdc-tab-nav-bar {
  border-bottom: 1px solid $line-grey;
}


.highlight {
  font-weight: bold;
  color: black;
}

.greyed-out {
  color: $menu-icon-grey;
}

.link-button {
  cursor: pointer;
  text-decoration: underline;
  color: $nevis-green-c1;
}

/* Used by LoadingIndicatorDirective to indicate if content is loading. */
.loading {
  /* Reduce opacity when the content of the element is loading. */
  opacity: 0.1;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

.disabled-opacity {
  opacity: 0.5;
}

.blurred-background {
  backdrop-filter: blur(4px);
  background: rgba(0, 0, 0, 0.32);
}
