@import "src/app/common/styles/mixins";

$mp-tile-padding: default_spacing_multiplier(2);
$mp-tile-border-width: 2px;
$mp-tile-border-radius: 5px;

$mp-tile-row-margin: default_spacing_multiplier(1.5);
$mp-tile-icon-size: default_spacing_multiplier(5);
$mp-tile-header-height: $mp-tile-icon-size;

// apparently this is 5 lines of default font
$mp-tile-description-height: 60px;

$mp-tile-category-gap: default_spacing_multiplier(0.5);
$mp-tile-category-border: 1px;
$mp-tile-category-padding-y: default_spacing_multiplier(0.25);
$mp-tile-categories-height: 2 * ($mp-tile-category-border + $mp-tile-category-padding-y + 15px + $mp-tile-category-padding-y + $mp-tile-category-border) + $mp-tile-category-gap ;

$mp-tile-height: calc($mp-tile-border-width + $mp-tile-padding + $mp-tile-header-height + $mp-tile-row-margin + $mp-tile-description-height + $mp-tile-row-margin + $mp-tile-categories-height + $mp-tile-padding + $mp-tile-border-width);
