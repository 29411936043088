as-split.hide-as-split-gutter {
  .as-split-gutter {
    flex-basis: 0 !important;
    position: relative;

    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
    }
  }

  &.as-horizontal .as-split-gutter::before {
    width: 5px;
    height: 100%;
  }

  &.as-vertical .as-split-gutter::before {
    width: 100%;
    height: 5px;
  }
}

as-split.as-horizontal.as-split-handle-only {
    .as-split-gutter {
    background: transparent none;
  }

}

as-split.as-horizontal.as-split-handle-upper {
  .as-split-gutter[role="separator"] {
    .as-split-gutter-icon {
      height: 50%;
      align-self: flex-start;
    }
  }
}
