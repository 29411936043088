@import "colors";

.default-inventory-background {
  background: $screen-white;
}

@mixin inventory-box-shadow($color) {
  box-shadow: 0 1px 20px $light-grey;
  outline: 2px solid $color;
  border-radius: 5px;
}

.default-inventory-box-shadow {
  @include inventory-box-shadow($light-grey);
}

.red-inventory-box-shadow {
  @include inventory-box-shadow($inventory-red);
}

.yellow-inventory-box-shadow {
  @include inventory-box-shadow($inventory-yellow);
}

.green-inventory-box-shadow {
  @include inventory-box-shadow($inventory-green);
}

.purple-inventory-box-shadow {
  @include inventory-box-shadow($inventory-purple);
}

.brown-inventory-box-shadow {
  @include inventory-box-shadow($inventory-brown);
}

.blue-inventory-box-shadow {
  @include inventory-box-shadow($inventory-blue);
}
