@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'), local('MaterialIcons-Regular'),
  url('../../../assets/fonts/MaterialIcons-Regular.ttf') format('ttf'),
  url('../../../assets/fonts/MaterialIcons-Regular.woff2') format('woff2');
}

// font family Roboto

@font-face {
  font-family: "Roboto";
  src: url("../../../assets/fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../assets/fonts/Roboto-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../assets/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../assets/fonts/Roboto-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal; // 400
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../assets/fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: normal; // 400
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../assets/fonts/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold; // 700
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../assets/fonts/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: bold; // 700
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../assets/fonts/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../assets/fonts/Roboto-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
// end of font family Roboto
