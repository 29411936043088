@import "colors";
@import "variables";
@import "mixins";

.done-icon {
  color: $nevis-green-c1;
}

.success-icon {
  color: $validation-green;
}

.warning-icon {
  color: $warning-orange;
}

.error-icon {
  color: $error-red;
}

.cancel-icon {
  font-size: $default-icon-size;
  cursor: pointer;
}

.help-icon {
  @include help-icon();
}

.help-icon-with-action {
  @include help-icon();
  cursor: pointer;
}