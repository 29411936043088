@import "fonts";

/* This file creates global CSS classes which can be reused in the backend - generated report HTML content. */

.global-menu-heading-font {
  font: $menu-heading-font;
}

.global-standard-bold-p-font {
  font: $standard-bold-p-font;
}

.global-standard-p-font {
  font: $standard-p-font;
}
