$default-font-size: 12px; // defines size of 1 rem

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-size-sm: 0.8rem;
$font-size-normal: 1rem;
$font-size-l: 1.2rem;
$font-size-xl: 1.3rem;
$font-size-2xl: 1.4rem;

$font_default_body: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
$code-font-family: SFMono-Regular, Monaco, Menlo, Consolas, "Liberation Mono", "Courier New", monospace;

$font_material: normal 24px Material Icons;
$default-font: 400 1rem $font_default_body;
$menu-heading-font: 400 1.3rem $font_default_body;
$menu-secondary-font: 400 1.4rem $font_default_body;
$modal-window-heading-font: 400 1.4rem $font_default_body;
$modal-window-heading-font-bold: 700 1.4rem $font_default_body;
$pattern-heading-font: 400 1.2rem $font_default_body;
$pattern-label-heading-font: 700 1.4rem $font_default_body;
$navigation-big-header-font: 400 1.2rem $font_default_body;
$navigation-big-header-logo-font: 400 2.3rem $font_default_body;
$help-heading-font: 700 1.2rem $font_default_body;
$welcome-heading-font: 400 2.2rem $font_default_body;
$standard-light-p-font: 400 1rem $font_default_body;
$standard-p-font: 400 1rem $font_default_body;
$standard-bold-p-font: 700 1rem $font_default_body;
$help-code-font: 400 1rem $code-font-family;
$small-p-font: 400 0.8rem $font_default_body;
$footer-p: 400 0.8rem $font_default_body;
$message-p: 400 0.7rem $font_default_body;
$login-heading-font: 400 2.45rem $font_default_body;
$report-header-h1: 400 1.5rem $font_default_body;
$report-header-h2: 400 1.3rem $font_default_body;
